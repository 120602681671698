import { styled } from "styled-components";
import { FlexColumnContainer, FlexContainer } from "../common/Elements";

export const SimilarItemContainer = styled(FlexColumnContainer)`
  border: 1px #ffffff solid;
  border-radius: 10px;
  max-width: 600px;
`;

export const DetailContainer = styled(FlexContainer)`
  padding: 20px;
`;

export const SimilarItemImgContainer = styled(FlexContainer)`
  max-width: 350px;
  width: 35%;
`;

export const Description = styled(FlexColumnContainer)`
  font-size: 1em;
  flex: 1;
  width: 100%;
  padding: 0 20px;
  @media screen and (max-width: 900px) {
    font-size: 0.7em;
  }
`;

export const Divider = styled(FlexContainer)`
  width: 100%;
  background: #e9e9e9;
  height: 2px;
  margin: 20px 0;
`;
