import React, { useState } from "react";
import {
  MapContainer,
  Border,
  LatLong,
  Title,
  Value,
  Location,
  Container,
  PhotoContainer,
  Downloadbtn,
  Sharebtn,
  AddressWrapper,
  SocialIcon,
  ButtonText,
  StyledDescription,
  FlexBetweenContainer,
  SocialIconWrapper,
  PricingAreaContainer,
  DetailsWrapper,
  BBInfoContainer,
  BBInfoGrid,
  BBInfoColSpan,
  FooterBtnContainer,
  SimilarItemsWrapper,
  SimilarItemsList,
  SeeMoreBtn,
} from "./Element";
import {
  CategoryText,
} from "../Billboards/BillboardLists/Elements";
import {
  FlexCenterContainer,
  FlexColumnContainer,
} from "../common/Elements";
import Update from "../Update";
import Print from "../../assets/images/print-icon.svg";
import Facebook from "../../assets/images/fb.svg";
import Twitter from "../../assets/images/twitter.svg";
import Instagram from "../../assets/images/instagram.svg";
import {
  useQuery
} from "@apollo/client";
import {
  SCREENS_QUERY,
  SCREEN_BY_ID_QUERY
} from "../../schema/gqlSchema";
import ImageSlider from "./Slider/slider";
import BBItemList from "./ItemLists/ItemList";
import { useMediaQuery } from "@mui/material";
import SimilarItem from "./SimilarItem";
import ContactPopup from "../ContactPopup";

const BillboardDetailComponent = ({ id, }) => {
  const showSimiliarItems = useMediaQuery("(max-width:1200px)");
  const { data } = useQuery(
    SCREEN_BY_ID_QUERY,
    {
      variables: { id },
      fetchPolicy: 'cache-and-network'
    }
  );
  const { data: similarItems } = useQuery(
    SCREENS_QUERY,
    {
      variables: { top: 3 },
      fetchPolicy: "network-only"
    }
  );

  const [open, setOpen] = useState(false);

  return (
    <FlexColumnContainer>
      <ContactPopup open={open} onClose={() => setOpen(false)} />
      <MapContainer>
        <ImageSlider data={data} />
      </MapContainer>
      <Title style={{ color: 'white', paddingTop: '50px', marginLeft: '5%', marginRight: '5%', borderBottom: '1px #eaeaea solid' }}>{ data?.findScreenContent?.flatData?.title }</Title>
      <DetailsWrapper>
        <AddressWrapper>
          <BBItemList data={data} />
        </AddressWrapper>
        {
          !showSimiliarItems &&
          <PricingAreaContainer>
            <PhotoContainer>
              <Downloadbtn onClick={() => setOpen(true)}>Download Pricing</Downloadbtn>
              <Sharebtn onClick={() => setOpen(true)}>Share</Sharebtn>
            </PhotoContainer>
          </PricingAreaContainer>
        }
        <BBInfoContainer>
          <Title>BILLBOARD INFORMATION</Title>
          <BBInfoGrid>
            <FlexColumnContainer>
              <StyledDescription>Latitude</StyledDescription>
              <Value>
                {data?.findScreenContent?.flatData?.geolocation?.latitude || 'N/A'}
              </Value>
              <Border />
            </FlexColumnContainer>

            <FlexColumnContainer>
              <StyledDescription>Longitude</StyledDescription>
              <Value>
                {data?.findScreenContent?.flatData?.geolocation?.longitude || 'N/A'}
              </Value>
              <Border />
            </FlexColumnContainer>

            <FlexColumnContainer>
              <StyledDescription>Media Panel</StyledDescription>
              <Value>
                {data?.findScreenContent?.flatData?.mediaPanel || 'N/A'}
              </Value>
              <Border />
            </FlexColumnContainer>

            <FlexColumnContainer>
              <StyledDescription>Display size (H x W)</StyledDescription>
              <Value>
                {data?.findScreenContent?.flatData?.displaySize || 'N/A'}
              </Value>
              <Border />
            </FlexColumnContainer>

            <BBInfoColSpan>
              <StyledDescription>Lighting</StyledDescription>
              <Value>-</Value>
              <Border />
            </BBInfoColSpan>

            <BBInfoColSpan>
              <Title>Description</Title>
              <Location>
                {data?.findScreenContent?.flatData?.description || 'N/A'}
              </Location>

              <Border />
            </BBInfoColSpan>

            <BBInfoColSpan>
              <Title>Categories</Title>
              {
                data?.findScreenContent?.flatData?.tags?.map(tag => (
                  <CategoryText>
                    {tag || 'N/A'}
                  </CategoryText>
                ))
              }
              <Border />
            </BBInfoColSpan>

            <BBInfoColSpan>
              <Title>Media Availability</Title>
              <LatLong>
                <Container>
                  <StyledDescription>Traffic volume</StyledDescription>
                  <Value>
                    {data?.findScreenContent?.flatData?.footFall}<span style={{ color: "#fff" }}>(per month)</span>
                  </Value>
                </Container>
                <Container>
                  <StyledDescription>Availability</StyledDescription>
                  <Value>
                    {data?.findScreenContent?.flatData?.availability}
                  </Value>
                </Container>
              </LatLong>
            </BBInfoColSpan>

          </BBInfoGrid>

          <FlexBetweenContainer>
            <FooterBtnContainer>
              <FlexCenterContainer>
                <Downloadbtn style={{ margin: 0 }} onClick={() => setOpen(true)}>Download Pricing</Downloadbtn>
              </FlexCenterContainer>
              <FlexCenterContainer onClick={() => setOpen(true)}>
                <img src={Print} alt="printIcon" width={50}></img>
              </FlexCenterContainer>
            </FooterBtnContainer>
            <SocialIconWrapper>
              <SocialIcon src={Facebook} alt="Facebook" onClick={() => setOpen(true)}></SocialIcon>
              <SocialIcon src={Twitter} alt="Twitter" onClick={() => setOpen(true)}></SocialIcon>
              <SocialIcon src={Instagram} alt="Instagram" onClick={() => setOpen(true)}></SocialIcon>
            </SocialIconWrapper>
          </FlexBetweenContainer>
        </BBInfoContainer>
        {
          !showSimiliarItems &&
          <SimilarItemsWrapper>
            <Title>More Similar Items</Title>
            <Border />
            <SimilarItemsList>
              {
                similarItems?.queryScreenContents?.map(similarItem => <SimilarItem data={similarItem} />)
              }
            </SimilarItemsList>
            <Border />
            <SeeMoreBtn to={'/billboards'}>
              <ButtonText>See More</ButtonText>
            </SeeMoreBtn>
          </SimilarItemsWrapper>
        }
      </DetailsWrapper>
      <Update />
    </FlexColumnContainer>
  );
};

export default BillboardDetailComponent;
