import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { CustomArrow, SliderImage, ImageSlide } from './SliderElement';
import { FiChevronRight } from 'react-icons/fi';
import DefaultImg from '../../../assets/images/billboard_example.png';

const ImageSlider = ({ data }) => {
  const [sliderImages, setSliderImages] = useState([]);

  // const defaultImages = [
  //   { url: DefaultImg },
  //   { url: DefaultImg },
  //   { url: DefaultImg },
  // ];

  const [activeSlide, setActiveSlide] = useState(0);

  const handleCustomArrowClick = () => {
    const nextSlide = (activeSlide + 1) % sliderImages?.length;
    setActiveSlide(nextSlide);
  };

  useEffect(() => {
    if (data?.findScreenContent?.flatData?.img?.length > 0) {
      setSliderImages(data?.findScreenContent?.flatData?.img);
    }
  }, [data])


  return (
    <Carousel showArrows={false} showStatus={false} showIndicators={false} infiniteLoop={true} autoPlay={true} showThumbs={false} selectedItem={activeSlide}
      onChange={setActiveSlide}>
      {
        // sliderImages?.length > 0 ?
        sliderImages?.map((image, index) => (
          <ImageSlide key={index}>
            <SliderImage src={image.url} alt={`Image ${index + 1}`} />
            <CustomArrow onClick={handleCustomArrowClick}>
              <FiChevronRight size={60} />
            </CustomArrow>
          </ImageSlide>
        ))
        // defaultImages.map((image, index) => (
        //   <ImageSlide key={index}>
        //     <SliderImage src={image.url} alt={`Image ${index + 1}`} />
        //     <CustomArrow onClick={handleCustomArrowClick}>
        //       <FiChevronRight size={60} />
        //     </CustomArrow>
        //   </ImageSlide>
        // ))
      }
    </Carousel>
  );
};

export default ImageSlider;
