import React, { useState } from "react";
import CustomDropdown from "../Dropdown";
import { SearchBar } from "../../SearchBar";
import {
  Card,
  Background,
  StyledParagraph,
  BillboardText,
  SearchButton,
  SectionContainer,
  SmallText,
} from "./Element";

const Section1 = () => {
  return (
    <Background id="home">
      <StyledParagraph style={{ marginTop: "10%" }}>
        Unlock the power of advertising with <br></br>easy buying and selling
        across all billboard types.
      </StyledParagraph>
      <SectionContainer>
        <Card>
          <StyledParagraph style={{ fontSize: "2rem" }}>
            Want to explore listed billboards?
          </StyledParagraph>
          <SearchButton
            as="a"
            href="https://buy.snipeads.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: "1.5rem",
              color: "white",
              fontFamily: "arial, sans-serif",
              height: "40px",
              width: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
              textDecoration: "none",
              margin: "10px auto",
              content: "center",
            }}
          >
            Join
          </SearchButton>
          <p
            style={{
              fontSize: "1.2rem",
              color: "white",
              marginTop: "30px",
              textAlign: "center",
              fontFamily: "arial, sans-serif",
            }}
          >
            Join our exclusive buying networks.
          </p>
        </Card>
      </SectionContainer>
    </Background>
  );
};

export default Section1;
