import { styled } from "styled-components";
import {
    FlexColumnContainer, 
    Span
} from '../common/Elements';
import bgImage from "../../assets/images/bg.jpg";
import { Dialog } from "@mui/material";

export const DialogContiner = styled(Dialog)`
    box-shadow: 0.2px 0.5px 8px rgba(255, 255, 255, 0.5);
    filter: drop-shadow(0px -2px 10px rgba(233, 223, 255, 0.4))
            drop-shadow(0px -2px 40px rgba(187, 155, 255, 0.16));
`;

export const Wrapper = styled(FlexColumnContainer)`
    background-color: #0b0121;
    background-size: cover;
    background-image: url(${bgImage});
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 30px;
`;

export const Header = styled(Span)`
    font-size: 1.5em;
    padding: 15px 0px;
    text-transform: uppercase;
`;

export const InputLabel = styled(Span)`
    font-size: 1em;
    padding-top: 20px;
    padding-bottom: 10px;
`;

export const SuccessMessage = styled.div`
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 10px;
`;