import styled from "styled-components";
import billboardImg from '../../assets/images/billboard_example.png'
import { Div, FlexCenterContainer, FlexColumnContainer, FlexContainer, FlexSpaceBetweenContainer } from "../common/Elements";
import { Link as LinkR } from "react-router-dom";

export const MapContainer = styled.div`
  text-align: none;
  background-image: url(${billboardImg});
  @media screen and (max-width: 900px) {
    height: 35vh;
  }
`;

export const FlexBetweenContainer = styled(FlexCenterContainer)`
  margin-top: 100px;
  justify-content: space-between;
  @media screen and (max-width: 560px) {
    flex-direction: column;
  }
`;

export const FooterBtnContainer = styled(Div)`
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;

  @media screen and (max-width: 400px) {
    grid-template-columns: auto;
    row-gap: 20px;
    align-items: center;
    justify-conent: center;
    text-align: center;
  }
`;

export const BBItemWrapper = styled(FlexContainer)`
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    white-space: nowrap;
  }
`

export const DetailsWrapper = styled(Div)`
  padding: 40px 5% 5% 5%;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 80px;
  color: white;

  @media screen and (max-width: 1200px) {
    grid-template-columns: auto;
  }
`;

export const BBInfoContainer = styled(FlexColumnContainer)`
  border-radius: 5px;
  padding-top: 50px;
`;

export const BBInfoGrid = styled(Div)`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 40px;
  row-gap: 20px;

  @media screen and (max-width: 500px) {
    grid-template-columns: auto;
  }
`;

export const BBInfoColSpan = styled(FlexColumnContainer)`
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: justify;

  @media screen and (max-width: 500px) {
    grid-column-end: 2;
  }
`;

export const Border = styled.div`
  border-bottom: 2px #e9e9e9 solid;
  padding-top: 10px;
`;

export const LatLong = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Longitude = styled(FlexColumnContainer)`
  flex: 1;
  padding-left: 20px;
`;

export const Engaging = styled(FlexContainer)`
  justify-content: space-between;
  padding-top: 10px;
`;

export const MediaWrapper = styled(FlexColumnContainer)`
  padding-top: 20px;
`;

export const Categories = styled(FlexColumnContainer)`
  padding-bottom: 20px;
`;


export const AddressWrapper = styled(FlexColumnContainer)`
`;

export const Address = styled.div`
  font-size: 1.5em;
  padding-bottom: 10px;
`;

export const FullAddress = styled.div`
width: 500px;
  font-size: 2em;
  padding-bottom: 20px;
  @media screen and (max-width: 900px) {
    font-size: 1em;
  }
`;

export const Title = styled.span`
  font-size: 1.5em;
  padding: 15px 0px;
  text-transform: uppercase;
  @media screen and (max-width: 900px) {
    font-size: 1em;
  }
`;

export const Value = styled.span`
  color: #fc1481;
  padding-top: 10px;
  font-size: 1.2em;
  @media screen and (max-width: 900px) {
    font-size: 1em;
  }
`;

export const Location = styled.span`
  padding-bottom: 10px;
`;

export const Direction = styled.span`
  padding-bottom: 10px;
`;

export const PricingAreaContainer = styled(FlexContainer)`
  padding: 30px 20px 0px 20px;

  border-radius: 13px;
  box-shadow: inset 0px 0.5px 0px rgba(255, 255, 255, 0.5);
  border: none;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: drop-shadow(0px -2px 10px rgba(233, 223, 255, 0.4)) drop-shadow(0px -2px 40px rgba(187, 155, 255, 0.16));
`;

export const PhotoContainer = styled.div`
  flex: 1;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const DetailPhoto = styled.img`
  padding: 25px;
  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 5px;
  }
`;

export const Downloadbtn = styled.button`
  border: none;
  padding-right: 30px;
  justify-content: flex-end;
  border-radius: 10px;
  margin-top: 20px;
  background: #fc1481;
  width: max-content;
  padding: 15px 75px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  align-self: center;
  transition: background-color 0.3s ease; /* Add transition for smoother color change */

  /* Change background color on hover */
  &:hover {
    background-color: #ff6ab4; /* Change to desired color */
  @media screen and (max-width: 900px) {
    white-space: nowrap;
  }
`;

export const Sharebtn = styled.button`
  background: transparent;
  padding-right: 30px;
  justify-content: flex-end;
  border: 1px #fc1481 solid;
  border-radius: 10px;
  margin-top: 20px;
  width: max-content;
  padding: 15px 110px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  align-self: center;
  transition: background-color 0.3s ease; /* Add transition for smoother color change */

  /* Change background color on hover */
  &:hover {
    background-color: #A472A1; /* Change to desired color */
`;

export const Image = styled.img`
  @media screen and (max-width: 900px) {
    width: 92%;
    padding: 20px 15px;
  }
`;

export const ImageHolder = styled.div`
  @media screen and (max-width: 900px) {
    display: flex;
  }
`;

export const Surroundings = styled.div`
  margin: 70px 0px 50px 50px;
  display: flex;
  @media screen and (max-width: 900px) {
    margin: 0px;
    flex-direction: column;
  }
`;

export const Content = styled.span`
  font-size: 2em;
  @media screen and (max-width: 900px) {
    margin-left: 20px;
    font-size: 1.5em;
    padding-top: 30px;
  }
`;

export const SimilarItemsWrapper = styled(FlexColumnContainer)`
  padding-top: 50px;
`;

export const SimilarItemsList = styled(Div)`
  display: grid;
  grid-template-columns: auto;
  row-gap: 20px;
  padding: 20px 0;
`;

export const FlexWrapper = styled(FlexContainer)`
@media screen and (max-width: 900px) {
  white-space: nowrap;
}
`;

export const SocialIcon = styled.img`
 padding-right: 10px;
`;

export const ButtonText = styled.span`
 font-size: 1.2em;
`;

export const StyledDescription = styled.span`
 padding-top: 7px;
 font-size: 1.2em;
 @media screen and (max-width: 900px) {
  font-size: 1em;
}
`;

export const SocialIconWrapper = styled.div`
  @media screen and (max-width: 560px) {
    padding-top: 20px;
    align-self: center;
  }
`;

export const ViewMoreBtn = styled(LinkR)`
  border: none;
  padding-right: 30px;
  justify-content: flex-end;
  border-radius: 10px;
  margin: 20px;
  background: #fc1481;
  width: max-content;
  padding: 10px 75px;
  color: white;
  align-self: center;
  text-decoration: none;
  @media screen and (max-width: 900px) {
    white-space: nowrap;
  }
`
export const SimilarItemImgContainer = styled(FlexCenterContainer)`
  max-width: 350px;
  width: 35%;
`;

export const SeeMoreBtn = styled(LinkR)`
  border-radius: 10px;
  background-color: #fc1481;
  margin-top: 20px;
  padding: 15px 20px;
  color: white;
  text-decoration: none;
  text-align: center;
`;