import styled from "styled-components";

export const SliderImage = styled.img`
 height: 45vh;
 @media screen and (max-width: 900px) {
    height: 35vh;
  }
`;

export const ImageSlide = styled.div`
position: relative;
`;

export const CustomArrow = styled.div`
position: absolute;
top: 50%;
right: 70px; 
transform: translateY(-50%);
width: 30px; 
height: 30px;
@media screen and (max-width: 900px) {
    right: 30px; 
}
`;