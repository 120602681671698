import React from "react";
import { ViewMoreBtn } from "./Element";
import { BBImage } from "../Billboards/BillboardLists/Elements";
import { 
  DetailContainer, 
  SimilarItemContainer, 
  SimilarItemImgContainer,
  Description,
  Divider
} from "./SililiarItemElements";

const SimilarItem = ({ data }) => {
  return (
    <SimilarItemContainer>
      <DetailContainer>
        <SimilarItemImgContainer>
          <BBImage src={data?.flatData?.img[0].url}></BBImage>
        </SimilarItemImgContainer>
        <Description>
          <span>
            {data?.flatData?.title || 'N/A'}
          </span>
          <Divider />
          <span> { data?.flatData?.address || 'N/A' }
          </span>
        </Description>
      </DetailContainer>
      <ViewMoreBtn target="_blank" to={`/billboard/${data?.id}`}>
        View More
      </ViewMoreBtn>
    </SimilarItemContainer>
  );
};

export default SimilarItem;
