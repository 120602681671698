import React, { useState } from 'react';
import {
    DialogContiner,
    Header,
    InputLabel,
    SuccessMessage,
    Wrapper
} from './Elements';
import { IconButton, InputBase, Paper } from '@mui/material';
import { Submitbtn } from '../Home/Contact/Element';
import { FlexSpaceBetweenContainer } from '../common/Elements';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const ContactPopup = ({ open, onClose }) => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const url = process.env.REACT_APP_EMAIL_API_URL;  // Use the same environment variable

    const handleOnSubmit = async () => {
        try {
            const result = await axios.post(url, {
                email: email,
                contactNumber: phone,
                subject: "Price Enquiry"
            });
            console.log(result);
            setSuccessMessage(true);
            // Clear the form fields
            setEmail('');
            setPhone('');
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <DialogContiner onClose={onClose} open={open} fullWidth={true}>
            <Wrapper>
                <FlexSpaceBetweenContainer>
                    <Header>Contact Us</Header>
                    <IconButton onClick={onClose}>
                        <CloseIcon style={{ color:'white' }} />
                    </IconButton>
                </FlexSpaceBetweenContainer>
                <InputLabel> Email </InputLabel>
                {/* <StateTextField variant='outlined' value={email}  /> */}
                <Paper>
                    <InputBase
                        style={{ height: '50px', width: '100%', padding: '0 10px' }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Paper>

                <InputLabel> Phone </InputLabel>
                <Paper>
                    <InputBase
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        style={{ height: '50px', width: '100%', padding: '0 10px' }}
                    />
                </Paper>

                <Submitbtn style={{ alignSelf: 'flex-end' }} onClick={handleOnSubmit}>
                    Submit
                </Submitbtn>

                {successMessage && (
                    <SuccessMessage>
                    <p>Your message has been sent successfully!</p>
                    </SuccessMessage>
                )}
            </Wrapper>
        </DialogContiner>
    )
}

export default ContactPopup;