import React from 'react';
import {
    BBAddressLabel,
    BBAddressText,
    BBItemContainer,
    BBItemContentContainer,
    BBItemFooterContainer,
    BBTagsContainer,
    CategoryLabel,
    CategoryList,
    CategoryText,
    Divider,
    LocationText,
    PriceText
} from '../../Billboards/BillboardLists/Elements';
import {
    FlexCenterContainer,
    FlexColumnContainer,
    FlexContainer
} from '../../common/Elements';
import PriceTag from '../../../assets/images/price_tag.svg';
import PinPink from '../../../assets/images/pin_pink.svg';

const BBItemList = ({ data }) => {
    const showInMapClicked = (lat, lng) => {
        window.open(`https://maps.google.com?q=${lat},${lng}`);
    };

    return (
        <BBItemContainer>
            <BBItemContentContainer>
                <FlexColumnContainer style={{ flex: 1 }}>
                    <BBTagsContainer>
                        <PriceText>
                            <div style={{ width: '30px' }}>
                                <img src={PriceTag} alt='price tag  icon'></img>
                            </div>
                            <span>{data?.findScreenContent?.flatData?.price || '100'} RM / Month</span>
                        </PriceText>

                        <LocationText onClick={() => showInMapClicked(data?.findScreenContent?.flatData?.geolocation?.latitude, data?.findScreenContent?.flatData?.geolocation?.longitude)}>
                            <div style={{ width: '30px' }}>
                                <img src={PinPink} alt='pin icon'></img>
                            </div>
                            <span>{data?.findScreenContent?.flatData?.address || 'N/A'}</span>
                        </LocationText>
                    </BBTagsContainer>
                    <BBAddressLabel>Address</BBAddressLabel>
                    <BBAddressText style={{ fontSize: "1em" }}>
                        {data?.findScreenContent?.flatData?.address || 'N/A'}
                    </BBAddressText>
                </FlexColumnContainer>
            </BBItemContentContainer>
            <Divider />
            <BBItemFooterContainer>
                <FlexContainer>
                    <CategoryLabel>Category : &nbsp;</CategoryLabel>
                    <CategoryList>
                        {
                            data?.findScreenContent?.flatData?.tags?.map(tag => (
                                <CategoryText>
                                    {tag || 'N/A'}
                                </CategoryText>
                            ))
                        }
                    </CategoryList>
                </FlexContainer>
                <FlexContainer style={{ flex: 'none' }}>
                    <CategoryLabel>Availability : &nbsp;</CategoryLabel>
                    <CategoryText>
                        {data?.findScreenContent?.flatData?.availability || 'N/A'}
                    </CategoryText>
                </FlexContainer>
            </BBItemFooterContainer>
        </BBItemContainer>
    )
}

export default BBItemList;